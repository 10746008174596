import React from "react";
import { graphql } from "gatsby";
import PageLayout from "./pageLayout";
import { CarouselImages } from "./carouselImages";
import { isMobile } from "../utils/isMobile";
import { Description } from "./description";
import { InfoProject } from "./info";

export default function ProjectTemplate3({ data }) {
  let { title, description, tags, paragraphs, meta } = data.content;

  let carouselIndicators = true;

  if (isMobile()) {
    carouselIndicators = false;
  }

  let carouselGroups = data.allFile.group.map((images, index) => {
    let paragraph = index > 0 && paragraphs && paragraphs.length ? paragraphs[index - 1] : null;

    return (
      <CarouselImages
        className={index === 0? "": "mb-5"}
        key={index}
        index={index+2}
        images={images.nodes}
        paragraph={paragraph}
        showIndicators={carouselIndicators}
      />
    );
  });

  let firstCarousel = carouselGroups[0];

  carouselGroups.shift();

  return (
    <PageLayout title={title}>
      <div className="d-flex justify-content-center mt-5">
        <div className={`container mr-2 mr-sm-4 ml-2 ml-sm-4`}>
          {firstCarousel}
          <InfoProject title={title} meta={meta} tags={tags.join(", ")} />
          <Description description={description} />
          <br />
          <div className="mt-3">{carouselGroups}</div>
        </div>
      </div>
      <br />
    </PageLayout>
  );
}

export const query = graphql`
  query($slug: String!, $carouselDir: String!) {
    content(fields: { slug: { eq: $slug } }) {
      title
      description {
        childMdx {
          body
        }
      }
      tags
      paragraphs {      
        childrenMdx {
          body
        }
      }
      meta
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { regex: $carouselDir }
      }
      sort: { fields: name }
    ) {
      group(field: relativeDirectory) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
